<template>
    <Form
        :submit="handleSubmit"
        :initialValues="initialValues"
    >
        <div class="form-narrow">
            <div class="form-section-title">authentication information</div>
            <div v-if="isNewUser" class="form-row w-1/2 pr-4">
                <TextField
                    name="email"
                    label="email*"
                    :validate="required"
                    hint="this email is used to sign in and can not be changed later"
                />
            </div>
            <div v-else class="form-row identity-list">
                <List
                    :columns="identityColumns"
                    :items="identities"
                    :css="{ bodyRowClass: 'row' }"
                ></List>
            </div>
            <div class="form-section-title">contact information</div>
            <div class="form-row">
                <TextField name="firstName" label="first name*" :editMode="!readonly" :validate="required"/>
                <TextField name="lastName" label="last name*" :editMode="!readonly" :validate="required"/>
            </div>
            <div class="form-row">
                <div class="w-1/2 pr-4">
                    <SelectInput name="role" label="Role*" :options="roles" :editMode="!readonly" :validate="required"/>
                </div>
            </div>
            <div class="form-row mt-6">
                <Tooltip :text="mfaTooltipText">
                    <template v-slot:label>
                        <CheckboxInput
                            name="mfaRequired"
                            label="force to set up MFA (multi factor authentication)"
                            :disabled="Boolean(initialValues.mfaRequired) || readonly"
                        />
                    </template>
                </Tooltip>
            </div>
            <ModalFooter :footer="footer" :tertiary="cancel"/>
        </div>
    </Form>
</template>

<script>
import { mapGetters } from "vuex";
import SelectInput from '@/components/form/SelectInput';
import TextField from '@/components/form/TextField';
import CheckboxInput from "@/components/auth/form/CheckboxInput";
import ValidatorMixin from '@/components/form/ValidatorMixin';
import NotifyMixin from "@/mixins/NotifyMixin";
import Tooltip from "@/components/ui/Tooltip";
import Form from "@/components/form/Form";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import EventBus from "@/utils/EventBus";
import { INIT_PROFILE_UPDATE } from "@/utils/authEvents";
import List from "@/components/list/List";

export default {
    name: 'QuextUserForm',
    components: {
        CheckboxInput,
        ModalFooter,
        Form,
        List,
        TextField,
        SelectInput,
        Tooltip,
    },
    mixins: [
        ValidatorMixin,
        NotifyMixin,
    ],
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
        initialValues: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['close'],
    data: function () {
        return {
            roles: [],
            identityColumns: [
                {
                    name: 'email',
                    title: 'email',
                    class: 'w-1/2 identity-cell',
                },
                {
                    name: 'cognitoUserId',
                    title: 'cognito user id',
                    class: 'w-1/2 identity-cell',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            profile: 'auth/profile',
        }),
        identities() {
            return this.initialValues.identities || [];
        },
        isNewUser() {
            return !this.initialValues.profileId;
        },
        readonly() {
            return Boolean(this.initialValues.deletedAt);
        },
        footer() {
            if (this.readonly) {
                return {
                    tertiaryButton: 'back',
                };
            }
            return {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            };
        },
        mfaTooltipText() {
            // fallback tooltip text for existing quext users
            if (this.readonly) {
                return null;
            }

            return this.initialValues.mfaRequired
                ? 'multi factor authentication is mandatory for quext admins'
                : 'please enable multi factor authentication setup for the current user';
        },
    },
    created() {
        this.$authDataProvider.getList('quextUsersRoles')
            .then((roles) => {
                this.roles = roles.map(({name: value, value: key}) => ({key, value}));
            })
            .catch((err) => this.notifyError(err));
    },
    methods: {
        handleSubmit(values) {
            this.onSubmit(values)
                .then(() => {
                    if (values.profileId === this.profile?.profileId) {
                        EventBus.emit(INIT_PROFILE_UPDATE);
                    }
                });
        },
        cancel(e) {
            e.preventDefault();
            this.$emit('close');
        },
    },
};
</script>
<style scoped>
.identity-list :deep(.row > .identity-cell) {
    @apply text-xs break-all;
}
</style>
